import config from '../../settings/index';
const apiUrl = config.apiUrl;
export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  GET_ALL_APARTMENTS : "GET_ALL_APARTMENTS",
  CLEAR_MENU: "CLEAR_MENU",
  DATA_LOADED: "DATA_LOADED",
  BUSINESS_INFO : "BUSINESS_INFO",
  GET_ALL_AMENITIES : "GET_ALL_AMENITIES",
  GET_ALL_PHOTOS : "GET_ALL_PHOTOS",
  GET_ALL_FLOORPLANS : "GET_ALL_FLOORPLANS",
  GET_ALL_AMENITY_PHOTOS : "GET_ALL_AMENITY_PHOTOS",
  GET_ALL_FEATURED_PHOTOS : "GET_ALL_FEATURED_PHOTOS",
  GET_ALL_APARTMENT_PHOTOS : "GET_ALL_APARTMENT_PHOTOS",
  GET_DESIGN : "GET_DESIGN",
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE
  }),
  getBusinessInfo: bid => {
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/buildings/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          console.log('this is body', body)
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "BUSINESS_INFO", payload: body[0] });
            dispatch(actions.getDesign(bid)); 
            dispatch(actions.getApartments(bid)); 
            dispatch(actions.getAmenities(bid)); 
            dispatch(actions.getPhotos(bid));  
            dispatch(actions.getFloorPlans(bid));
            dispatch(actions.getAmenityPhotos(bid));        
            dispatch(actions.getFeaturedPhotos(bid));            
            dispatch(actions.getApartmentPhotos(bid));
          }
        });
      };
  },
  getDesign: bid => {
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/designs/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_DESIGN", payload: body[0] });
                      
          }
        });
      };
  },
  getApartments: bid => {
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/apartments/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENTS", payload: body });
                      
          }
        });
      };
  },
  getPhotos: bid =>{
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/photos/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_PHOTOS", payload: body });
                 
          }
        });
      };
  },
  getAmenities: bid =>{    

    return (dispatch, getState) => {
      return fetch(`${apiUrl}/amenities/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_AMENITIES", payload: body });
          }
        });
      };
  },
  getFloorPlans: bid =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/floorplans/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_FLOORPLANS", payload: body });
          }
        });
      };
  },
  getAmenityPhotos: bid =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/photos/type/amenity/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_AMENITY_PHOTOS", payload: body });
          }
        });
      };
  },
  getFeaturedPhotos: bid =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/photos/type/featured/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {                 
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_FEATURED_PHOTOS", payload: body });

          }
        });
      };
  },
  getApartmentPhotos: bid =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/photos/type/apartment/${bid}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({ type: "DATA_LOADED", payload: true });
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENT_PHOTOS", payload: body });
            dispatch({ type: "DATA_LOADED", payload: true });
          }
        });
      };
  },
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== "DesktopView";
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU })
};
export default actions;
