import React from "react";

const Checkmark = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "300"}
      height={props.height ? props.height : "300"}
      x="0"
      y="0"
      viewBox="0 0 100 100"
    >
      <path
        style={{
          WebkitTextIndent: "0",
          textIndent: "0",
          WebkitTextAlign: "start",
          textAlign: "start",
          lineHeight: "normal",
          WebkitTextTransform: "none",
          textTransform: "none",
          blockProgression: "tb",
          marker: "none",
          InkscapeFontSpecification: "Sans",
        }}
        fill={props.color ? props.color : "#52c41a" }
        strokeWidth="10.639"
        d="M50 957.362c-24.817 0-45 20.183-45 45s20.183 45 45 45 45-20.183 45-45-20.183-45-45-45zm0 6c21.575 0 39 17.426 39 39 0 21.575-17.425 39-39 39s-39-17.425-39-39c0-21.574 17.425-39 39-39zm21.156 20.969a3 3 0 00-2.062.937L44 1011.05l-10.594-10.907a3.006 3.006 0 10-4.312 4.188l12.75 13.125a3 3 0 004.312 0l27.25-28a3 3 0 00-2.25-5.125z"
        color="#52c41a"
        enableBackground="accumulate"
        fontFamily="Sans"
        overflow="visible"
        transform="translate(0 -952.362)"
      ></path>
    </svg>
  );
}

export default Checkmark;