import { api } from '../../settings';
import axios from "axios";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { user } from "./reducer"; 

const userActions = {
  ADD_USER: "ADD_USER",
  GET_USER: "GET_USER",
  EDIT_USER: "EDIT_USER",
  CHECK_USER: "CHECK_USER",
  DELETE__USER: "DELETE__USER",
  CHANGE_USER: "CHANGE_USER",
  EDIT_VIEW: "EDIT_VIEW",
  changeUser: id => ({
    type: userActions.CHANGE_USER,
    id
  }),
  getUser: (user) => {
    return (dispatch, getState) => {
      return fetch('http://localhost:5432/users/'+user.id)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({
              type: userActions.GET_USER,
              user: body
            });
          }
        });
      };
  },
  checkUser: () => {
   const profile = localStorage.getItem('profile');
   const user_id = localStorage.getItem('user_id').split('|')[1];
    return (dispatch, getState) => {
      return fetch('http://localhost:5432/users/uid/'+user_id)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            if(body){
              dispatch(userActions.getUser(body));
            }
            else{
              dispatch(userActions.addUser(profile, user_id));
            }
          }
        });
      };
  },
  addUser: (profile, user_id) => {    
    return function (dispatch, getState) {
      const p = JSON.parse(profile);
      (async () => {
        const rawResponse = await fetch('http://localhost:5432/users/register', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            f_name: p.idTokenPayload.given_name, 
            l_name: p.idTokenPayload.family_name, 
            email: p.idTokenPayload.email, 
            photo: p.idTokenPayload.picture, 
            user_id: user_id 
          })
        });
        const content = await rawResponse.json();
        dispatch(userActions.getUser(content));
      })();  
    }
  },
  editUser: newUser => {
    return (dispatch, getState) => {
      const _user = getState().User.user;
      dispatch({
        type: userActions.EDIT_USER,
        user: _user
      });
    };
  },
  deleteUser: id => {
    return (dispatch, getState) => {
      const _user = getState().User.user;
      const selectedId = getState().User.selectedId;
      dispatch({
        type: userActions.DELETE__USER,
        contacts: _user,
        selectedId: id === selectedId ? undefined : selectedId
      });
    };
  },
  viewChange: view => ({
    type: userActions.EDIT_VIEW,
    view
  })
};
export default userActions;
