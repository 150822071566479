import React, { Component } from 'react';
import { Layout, Tag, Col, Row } from 'antd';
import { HomeCarousel } from '../home.style';


export default class extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { info } = this.props;
    return (
      <div>
        <h1 className="building-title">{info.name}</h1>
        <a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + info.address + info.city + info.region + info.postal_code}>{info.address} {info.city} {info.region} {info.postal_code}</a>
        <Row type="flex" justify="center">
          <Col lg={{ span: 14}} sm={{ span: 14 }} style={{ margin: '0 auto' }}>
            <p className="business-description">{info.description}</p>
          </Col>
        </Row>
      </div>
    );
  }
}


