export default {
  apiUrl: process.env.NODE_ENV === 'development'  ? 'http://localhost:3001':'https://api.getreal.nyc'
};
const siteConfig = {
  siteName: 'Get Real',
  siteIcon: 'ion-flash',
  footerText: 'Get Real ©2020'
};

const api = {
  url : 'http://localhost:3001/'
}

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};
const Auth0Config = {
  domain: 'getreal.auth0.com',
  clientID: 'mtTSa3EqlIsh9PH4uRldDyqlcZAUNdjs',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      responseType: 'token id_token',
      redirectUrl: 'http://localhost:3000/auth0loginCallback'
    },
    languageDictionary: {
      title: 'Real Deal',
      emailInputPlaceholder: 'Your Email',
      passwordInputPlaceholder: '********'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#000',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};
const firebaseConfig = {
  apiKey: 'AIzaSyC7OMmN4E7P088u8WaEw0yKlXfQWpjVBuA',
  authDomain: 'postgres-236617.firebaseapp.com',
  databaseURL: 'https://postgres-236617.firebaseio.com/',
  projectId: 'postgres-236617',
  storageBucket: 'postgres-236617.appspot.com',
  messagingSenderId: '777985239975'
};
const googleConfig = {
  apiKey: 'AIzaSyAh9NoIKbnOXogkilLnh_MNFZXcQRfmUTo' //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};
const youtubeSearchApi = '';
export {
  api,
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi
};
