import { business } from "./reducer";
import config from '../../settings/index';
const apiUrl = config.apiUrl;
function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
  const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const businessActions = {
  ADD_CONTACT: "ADD_CONTACT",
  GET_CONTACTS: "GET_CONTACTS",
  GET_ALL_AMENITIES: "GET_ALL_AMENITIES",
  GET_ALL_APARTMENTS: "GET_ALL_APARTMENTS",
  GET_ALL_APARTMENT_PHOTOS : "GET_ALL_APARTMENT_PHOTOS",
  GET_ALL_APARTMENT_FLOORPLANS : "GET_ALL_APARTMENT_FLOORPLANS",
  GET_ALL_PHOTOS: "GET_ALL_PHOTOS",
  GET_ALL_FLOORPLANS: "GET_ALL_FLOORPLANS",
  EDIT_CONTACT: "EDIT_CONTACT",
  SAVE_CONTACT: "SAVE_CONTACT",
  DELETE__CONTACT: "DELETE__CONTACT",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  EDIT_VIEW: "EDIT_VIEW",
  changeBusiness: id => {
    return (dispatch, getState) => {
    dispatch({
        type: businessActions.CHANGE_CONTACT,
        id
      });
    dispatch(businessActions.getAmenities(id));
    dispatch(businessActions.getApartments(id));
    dispatch(businessActions.getPhotos(id));
    dispatch(businessActions.getApartmentPhotos(id, 5));
    dispatch(businessActions.getApartmentFloorPlans(id, 5));
    dispatch(businessActions.getFloorPlans(id));
    }
  },
  getBusiness: () =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/business`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_CONTACTS", payload: body });
          }
        });
      };
  },
  getAmenities: (business_id) =>{    
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/amenity/all/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_AMENITIES", payload: body });
          }
        });
      };
  },
  getPhotos: (business_id) =>{
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/photos/all/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_PHOTOS", payload: body });
          }
        });
      };
  },
  getApartments: (business_id) =>{
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/apartment/all/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENTS", payload: body });
          }
        });
      };
  },
  getFloorPlans: (business_id) =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/floorplans/all/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_FLOORPLANS", payload: body });
          }
        });
      };
  },
  addAmenity: (amenity) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/amenity/add`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: amenity.business_id, 
            type: amenity.type, 
            name: amenity.name
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getAmenities(amenity.business_id));
      })();  
    }
  },
  addPhoto: (photo) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/photos/add`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: photo.business_id, 
            type: photo.type, 
            name: photo.name,
            url: photo.url
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getPhotos(photo.business_id));
      })();  
    }
  },
  addApartment: (apartment, business) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/apartment/add`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: business.id,
            title : apartment.title,
            bedrooms : apartment.bedrooms,
            bathrooms : apartment.bathrooms,
            price : apartment.price,
            availability : apartment.availability,
            amenities : apartment.amenities,
            description : apartment.description,
            size  : apartment.size,
            featured : apartment.featured
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getApartments(business.id));
      })();  
    }
  },
  addFloorPlan: (floorPlan) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/floorplans/add`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: floorPlan.business_id, 
            type: floorPlan.type, 
            name: floorPlan.name,
            url: floorPlan.url
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getFloorPlans(floorPlan.business_id));
      })();  
    }
  },
  getApartmentPhotos: (businessId, apartmentId) => {    
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/photos/apartment/${businessId}/${apartmentId}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENT_PHOTOS", payload: body });
          }
        });
      };
  },
  getApartmentFloorPlans: (businessId, apartmentId) => {  
    return (dispatch, getState) => {      
      return fetch(`${apiUrl}/floorPlans/apartment/${businessId}/${apartmentId}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENT_FLOORPLANS", payload: body });
          }
        });
      };
  },
  deleteAmenity: (amenity) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/amenity/delete/${amenity.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getAmenities(amenity.business_id));
      })();
    }
  },
  deletePhoto: (photo) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/photos/delete/${photo.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getPhotos(photo.business_id));
      })();
    }
  },
  deleteApartment: (apartment) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/apartment/delete/${apartment.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getApartments(apartment.business_id));
      })();
    }
  },
  deleteFloorPlan: (floorPlan) => {    
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/floorplans/delete/${floorPlan.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getFloorPlans(floorPlan.business_id));
      })();
    }
  },
  addBusiness: () => {
    const newBusiness = {    
      name : '',
      address : '',
      postal_code : '',
      region : '',
      city : '',
      url : '',
      website_template : '',
      apartments : '',
      amenities : '',
      photos : '',
      payments : '',
      multiple : '',
      created : '',
      modified : ''
    };
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/business/total`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            newBusiness.id = body.count + 1;
            (async () => {
              const rawResponse = await fetch(`${apiUrl}/business/register`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(newBusiness)
              });
              const content = await rawResponse.json();
            })();  
            dispatch({
              type: businessActions.ADD_CONTACT,
              business: [...getState().Business.business, newBusiness],
              selectedId: newBusiness.id
            });
          }
      });
    };
  },
  editBusiness: newBusiness => {
    return (dispatch, getState) => {
      const business = getState().Business.business;
      const newBusiness = [];
      business.forEach(business => {
        if (business.id === newBusiness.id) {
          newBusiness.push(newBusiness);
        } else {
          newBusiness.push(business);
        }
      });
      dispatch({
        type: businessActions.EDIT_CONTACT,
        business: newBusiness.sort(ascendingSort)
      });
    };
  },
  editPhoto:(photo) => {    
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/photos/update/${photo.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(photo)
        });
//        const content = await rawResponse;
      })();
    };    
  }, 
  editApartment:(apartment) => {    
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/apartment/update/${apartment.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(apartment)
        });
//        const content = await rawResponse;
      })();
    };    
  },   
  editFloorPlan:(floorPlan) => {    
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/floorplans/update/${floorPlan.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(floorPlan)
        });
//        const content = await rawResponse;
      })();
    };    
  }, 
  deleteBusiness: id => {
    return (dispatch, getState) => {
      const business = getState().Business.business;
      const selectedId = getState().Business.selectedId;
      const newBusiness = [];
      business.forEach(b => {
        if (b.id === id) {
        } else {
          newBusiness.push(b);
        }
      });
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/business/delete/${id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
//        const content = await rawResponse;
      })();
      dispatch({
        type: businessActions.DELETE__CONTACT,
        business: newBusiness,
        selectedId: id === selectedId ? undefined : selectedId
      });
    };
  },
  saveBusiness:(contact) => {    
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiUrl}/business/update/${contact.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contact)
        });
//        const content = await rawResponse;
      })();
    };    
  }, 
  viewChange: (view) => {

    return (dispatch, getState) => {
      dispatch({
          type: businessActions.EDIT_VIEW,
          view
      });
    }
  }
};
export default businessActions;
