import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import axios from 'axios';
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import ThemeSwitcher from "../../containers/ThemeSwitcher";
import AppRouter from "./AppRouter";
import { siteConfig } from "../../settings";
import { AppLocale } from "../../dashApp";
import themes from "../../settings/themes";
import AppHolder from "./commonStyle";
import GoogleFontLoader from 'react-google-font-loader';

import "./global.css";

const { Content, Footer } = Layout;
const { login, logout } = authAction;
const { toggleAll, getBusinessInfo } = appActions;

let host = window.location.host;
let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.length >= 2) {
  subdomain = parts[0];

  // Remove the subdomain from the parts list
  //parts.splice(0, 1);
  // Set the location to the new url
  ///window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
}

localStorage.setItem("bid", subdomain);

const bid = localStorage.getItem("bid");
let c = 0;


let designStyles;

export class App extends Component {

  constructor(props) {
    super(props);
    this.state = { initialSetUp : false, design: {} };
  }

  componentDidUpdate(props) {
    const { businessInfo } = this.props;
    const { initialSetUp } = this.state;

    console.log('this is businessInfo1234', businessInfo)

    if (
      businessInfo.design &&
      !initialSetUp
    ) {
      this.runStateSetUp();
      this.setState({
        initialSetUp: true,
      });
    }

  }

  runStateSetUp = () => {
    const { businessInfo } = this.props;
    this.setState({design : businessInfo.design})
  }


  render() {
    const { url } = this.props.match;
    const {design} = this.state;
    const {
      auth,
      locale,
      selectedTheme,
      height,
      getBusinessInfo,
      businessInfo,
    } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;

    if (c == 0) {
      this.props.getBusinessInfo(bid);
      c++;
    }


    this.props.login();

    designStyles = {
      primary : design.primary,
      secondary : design.secondary,
      primaryColor : {
        color : design.primary ? design.primary : '#000'
      },
      primaryBackground : {
        background : design.primary ? design.primary : '#000'
      },
      primaryBorder : {
        border: '1px solid #fff',
        borderRadius : '4px',
        borderColor : design.primary ? design.primary : '#000'
      },
      secondaryColor : {
        color : design.secondary ? design.secondary : '#000'
      },
      secondaryBackground : {
        background : design.secondary ? design.secondary : '#000'
      },
      headerFont : {
        fontFamily : design.header,
        fontWeight : '700'
      },
      copyFont : {
        fontFamily : design.copy,
        fontWeight : '700'
      }
    }

   
    

    return (

      <LocaleProvider locale={currentAppLocale.antd}>
      {design && 
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                 <GoogleFontLoader
                    fonts={[
                      {
                        font: design.header ? design.header : 'Arial',
                        weights: [400, '400i'],
                      },
                      {
                        font: design.copy ? design.copy : 'Arial',
                        weights: [400, 700],
                      },
                    ]}
                    subsets={['cyrillic-ext', 'greek']}
                  />
                <Topbar businessInfo={businessInfo} businessId={bid} url={url} designStyles={designStyles} />
                <Layout style={{ flexDirection: "row", overflowX: "hidden" }} >
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: "70px 0 0",
                        flexShrink: "0",
                        background: "#f1f3f6",
                        position: "relative",
                      }}

                    >
                      <AppRouter designStyles={designStyles}  businessInfo={businessInfo} url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: "#ffffff",
                        textAlign: "center",
                        borderTop: "1px solid #ededed",
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
                <ThemeSwitcher />
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
        }
      </LocaleProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.App.heightm,
    businessInfo: state.App.businessInfo,
  }),
  { login, logout, toggleAll, getBusinessInfo }
)(App);
