import React, { Component } from 'react';
import { Layout, Carousel, Col, Row } from 'antd';
import {HomeCarousel} from '../home.style';


export default class extends Component {
  constructor(props) {
    super(props);
    this.singlePhoto = this.singlePhoto.bind(this);
  }

  singlePhoto(photo, size) {
    let _size;
    if(size == 'large'){
      _size = '640px'
    }
    else{
      _size = '640px'
    }
    return <div key={photo.id}> <div className="featuerdImage" style={{ backgroundImage: "url("+photo.url+")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center center", height: _size, width: '100%' }}></div> </div>;
  }

  getPosition(position){
    let _p;
    if(position){
      _p = position;
    }
    else{
      _p = 'bottom';
    }
    return _p;
  }

  featuredCount = (data) => {
    if(data.length > 1){
      return true;
    }
    else{
      return false;
    }
  };



  render() {
  	const { carouselImages, size, position } = this.props;		
    return (    	
      <HomeCarousel>
        {carouselImages && 
          <Carousel easing="easeInOutQuad" draggable="true" autoplay={this.featuredCount(carouselImages)} dots={true} dotPosition={this.getPosition(position)}>
          {carouselImages.map(photo => this.singlePhoto(photo, size))}
        </Carousel>}
      </HomeCarousel>      
 
    );
  }
}


