import React, { Component } from 'react';
import { Layout, Tag, Col, Row, Tooltip, Divider } from 'antd';
import { HomeCarousel } from '../home.style';
import { BusinessInfoContainer } from '../../../containers/Home/home.style';
import BuildingInformation from '../buildingInformation';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      align : ''
    }
  }

  componentDidMount(){
    const {align, size} = this.props;
    if(align){
      this.setState({align, size})
    }
  }



  amenityDescription(description, designStyles) {
    if (description) {
      return (this.props.description.map(amenity => (
          <Col span={16}>
            <p  style={{...designStyles.copyFont}} className="amenity-description" key={amenity.name}>{amenity.name}</p>
          </Col>)))
    }
  }

  amenity(amenities, designStyles, align, size){
    return <Col span={size ? size : 14} offset={align ? 0 : 10}>
        
        {amenities && amenities.map((amenity,index) => (
            
              <Tag style={{...designStyles.secondaryColor, ...designStyles.primaryBackground, float:'right', fontWeight:'bold', fontSize:'16px'}} key={index}>{amenity.name ? amenity.name : amenity.label}</Tag>
            
        ))}
        </Col>
  }

  render() {
    const { description, amenities, designStyles } = this.props;
    const {align, size} = this.state;
    return (
      <div>


        <Row type="flex" style={{alignItems: align ? align : 'center'}}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} className={align ? 'amenity-box align-left' : 'amenity-box align-right'}>
            <Row  style={{textAlign:'justify'}}>
              {this.amenity(amenities, designStyles, align, size)}            
            </Row>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="border-left amenity-box align-left">
            <Row  style={{textAlign:'justify'}}>
              {this.amenityDescription(description, designStyles)}
            </Row>
          </Col>
        </Row>
        

      </div>
    );
  }
}


