import Auth from './auth/reducer';
import App from './app/reducer';
import Business from './business/reducer';
import User from './user/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import DevReducers from '../customApp/redux/reducers';
import Articles from './articles/reducers';
import drawer from './drawer/reducer';
import modal from './modal/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,  
  Business,
  modal,
  drawer,
  ...DevReducers,
};
