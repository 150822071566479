import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, LocaleProvider, Select, Row, Col } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import ThemeSwitcher from "../../containers/ThemeSwitcher";
import AppRouter from "../App/AppRouter";
import { siteConfig } from "../../settings";
import { AppLocale } from "../../dashApp";
import themes from "../../settings/themes";
import Apartments from "../../components/home/apartments";
import AppHolder from "../App/commonStyle";
import Masonry from 'react-masonry-css'

import "../App/global.css";
import {
  AvailableApartments,
} from "../Home/home.style";

const { Option } = Select;


const {
  getBusinessInfo,
  getApartments,
  getAmenities,
  getPhotos,
  getFloorPlans,
  getAmenityPhotos,
  getFeaturedPhotos,
  getApartmentPhotos,
  dataLoaded,
  toggleAll,
} = appActions;

const { Content, Footer } = Layout;
const { login, logout } = authAction;

let host = window.location.host;
let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.length >= 2) {
  subdomain = parts[0];

  // Remove the subdomain from the parts list
  //parts.splice(0, 1);
  // Set the location to the new url
  ///window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
}

localStorage.setItem("bid", subdomain);

const bid = localStorage.getItem("bid");
let c = 0;


export class App extends Component {


  constructor(props) {
    super(props);
    this.state = { autoplay: false, initialSetUp: false, bedrooms: 0, bathrooms: 0 };
  }

   componentDidMount() {
    const { businessInfo, dispatch } = this.props;
        this.props.getBusinessInfo(bid);
//    getBusinessInfo(bid);
  }

  componentDidUpdate(props) {
    const { dispatch, businessInfo } = this.props;
    const { initialSetUp } = this.state;
    console.log('this is businessInfo', businessInfo);
    if (
      businessInfo.apartments &&
      businessInfo.photos &&
      !initialSetUp
    ) {
      this.runStateSetUp();
      this.setState({
        initialSetUp: true,
      });
    }
  }

  handleChange = (value, type) => {
    const { businessInfo } = this.props;    
    const {bedrooms, bathrooms} = this.state;
      this.setState({
        [type] : Number(value)
      }, ()=>{



        const availableApartments = businessInfo.apartments.filter(
          (apartment) => apartment.bedrooms >= this.state.bedrooms && apartment.bathrooms >= this.state.bathrooms
        )


        this.setState({availableApartments})


      })

      


  }

  runStateSetUp = () => {
    const { businessInfo } = this.props;    
     
    this.setState(
      {
        availableApartments: businessInfo.apartments.filter(
          (apartment) => apartment.on_market === true
        ),
        featuredMain: this.filterFeatured("featured_main"),
        apartmentPhotos: this.filterApartmentPhotos(),
        featuredApartmentAmenity: this.filterFeatured(
          "featured_apartment_amenity"
        ),
        featuredBuildingAmenity: this.filterFeatured(
          "featured_business_amenity"
        ),
        featuredApartmentImage: this.filterFeatured("featured_apartment_image"),
      },
      () => {
        this.setState({
          loaded: true,
        });
      }
    );
  };


  filterFeatured(type) {
    const { businessInfo } = this.props;
    const photos = businessInfo.photos.all;
    let featuredPhotos = [];
    photos &&
      photos.map((photo) => {
        photo.type &&
          photo.type.featured.length &&
          photo.type.featured.map((fPhoto) => {
            if (fPhoto.key === type) {
              featuredPhotos.push(photo);
            }
          });
      });
    return featuredPhotos;
  }

  filterApartmentPhotos() {
    const { businessInfo } = this.props;
    const photos = businessInfo.photos.all;
    let apartmentPhotos = [];
    photos &&
      photos.map((photo) => {
        if (photo.type && photo.type.apartment.length) {
          apartmentPhotos.push(photo);
        }
      });
    return apartmentPhotos;
  }



  render() {
    const { url } = this.props.match;
    const {
      auth,
      locale,
      selectedTheme,
      height,
      getBusinessInfo,
      businessInfo,
    } = this.props;

    console.log('this is businessInfo', businessInfo);

    
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;





     const {
 
      availableApartments,
      apartmentPhotos,
    } = this.state;

    console.log('this is state', this.state);

    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Topbar businessInfo={businessInfo} url={url} />
                <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: "70px 0 0",
                        flexShrink: "0",
                        background: "#f1f3f6",
                        position: "relative",
                      }}
                    >
                    <AvailableApartments>
                    <Row type="flex" gutter={16}>
                    <Col span={24}>
                      <h2 style={{width:'100%'}}>{businessInfo.name} Available Apartments</h2>
                    </Col>
                    <Col span={24} style={{textAlign : 'center', marginBottom:'20px'}}>
                      <label>Bedrooms : </label>
                      <Select defaultValue="0" style={{ width: 120, marginRight:'20px' }} onChange={(e)=>this.handleChange(e, 'bedrooms')}>
                        <Option value="0">0+</Option>
                        <Option value="1">1+</Option>
                        <Option value="2">2+</Option>
                        <Option value="3">3+</Option>
                      </Select>
                      <label>Bathrooms : </label>
                      <Select defaultValue="0" style={{ width: 120 }} onChange={(e)=>this.handleChange(e, 'bathrooms')}>
                        <Option value="0">0+</Option>
                        <Option value="1">1+</Option>
                        <Option value="2">2+</Option>
                        <Option value="3">3+</Option>
                      </Select>

                    </Col>                   
              </Row>

                    
                      {availableApartments && 
                      <Apartments
                        apartments={availableApartments}
                        photos={apartmentPhotos}
                        apartmentPhotos={apartmentPhotos}
                        businessInfo={businessInfo}
                      ></Apartments>
                      }

            </AvailableApartments>
                    </Content>
                    <Footer
                      style={{
                        background: "#ffffff",
                        textAlign: "center",
                        borderTop: "1px solid #ededed",
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
                <ThemeSwitcher />
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.App.heightm,
    businessInfo: state.App.businessInfo,
  }),
  { login, logout, toggleAll, getBusinessInfo }
)(App);
