import React, { Component } from "react";
import { Layout, Card, Col, Row, Descriptions, Drawer, Badge } from "antd";
import { HomeCarousel } from "../home.style";
import BuildingCarousel from "../carousel";
import AmenityInformation from "../amenityInformation";
import Masonry from 'react-masonry-css'
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  getApartmentFeaturePhoto(id) {
    const { photos } = this.props;
    const apartmentPhotos = photos;
    let apartmentUrl =
      "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png";
    for (var i = 0; i < apartmentPhotos.length; i++) {
      for (var ii = 0; ii < apartmentPhotos[i].type.apartment.length; ii++) {
        const idCheck = apartmentPhotos[i].type.apartment[ii].apartment_id;
        if (idCheck == id) {
          apartmentUrl = apartmentPhotos[i].url;
          for (
            var iii = 0;
            iii < apartmentPhotos[i].type.featured.length;
            iii++
          ) {
            if (
              apartmentPhotos[i].type.featured[iii].key ===
              "featured_apartment_image"
            ) {
              apartmentUrl = apartmentPhotos[i].url;
            }
          }
        }
      }
    }

    return apartmentUrl;
  }

  getAptPhotos(aptNum) {
    const photos = this.props.apartmentPhotos;
    let _photos = [];
    for (var i = 0; i < photos.length; i++) {
      const apt = photos[i].type.apartment;
      for (var ii = 0; ii < apt.length; ii++) {
        if (apt[ii].key === aptNum) {
          _photos.push(photos[i]);
        }
      }
    }
    return _photos;
  }

  showDrawer = (apartment) => {

    this.setState(
      {
        selectedApartment: apartment,
      },
      () => {

        this.setState({
          visible: true,
        });
      }
    );
  };

  formatDate(d) {
    let date = new Date(d);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (d = mm + "/" + dd + "/" + yyyy);
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  render() {
    const { apartments, photos, businessInfo, apartmentPhotos } = this.props;
    return (
      <Row type="flex" gutter={16}>
       {apartments.length ? <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="apartment-grid"
                      columnClassName="apartment-grid_column">


       {apartments.map((apartment) => (
          <Col lg={{ span: 24 }} sm={{ span: 24 }}>
            <Card
              hoverable
              key={apartment.title}
              onClick={() => this.showDrawer(apartment)}
              cover={
                <img
                  alt={apartment.title}
                  height="200px"
                  src={this.getApartmentFeaturePhoto(apartment.id)}
                />
              }
            >
              <Row type="flex" justify="start" gutter={16}>
                <Col span={12}>{apartment.apt_number}</Col>
                <Col span={12}>{apartment.price}</Col>
              </Row>
              <Row type="flex" justify="start" gutter={16}>
                <Col span={24}>
                  {apartment.bedrooms} bedrooms, {apartment.bathrooms} bathrooms
                </Col>
              </Row>
              <Row type="flex" justify="start" gutter={16}>
                <Col span={24}>{apartment.title}</Col>
              </Row>
              <Row type="flex" justify="start" gutter={16}>
                <Col span={24}>{apartment.description}</Col>
              </Row>
            </Card>
          </Col>
        ))}

        </Masonry> : 
          <p>No apartments found</p>
        }
        
        {this.state.selectedApartment ? (
          <Drawer
            title={this.state.selectedApartment.title}
            width="75%"
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <BuildingCarousel
              carouselImages={this.getAptPhotos(
                this.state.selectedApartment.apt_number
              )}
              size="medium"
            ></BuildingCarousel>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                background: "#fff",
                padding: "10px 20",
              }}
            >
              <label>Description:</label>
              <p>{this.state.selectedApartment.description}</p>
              <Descriptions bordered>
              <Descriptions.Item label="Apt Number">
                {this.state.selectedApartment.apt_number}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                ${this.state.selectedApartment.price}
              </Descriptions.Item>
              <Descriptions.Item label="Available">
                {this.formatDate(this.state.selectedApartment.availability)}
              </Descriptions.Item>
              <Descriptions.Item label="Bedrooms">
                {this.state.selectedApartment.bedrooms}
              </Descriptions.Item>
              <Descriptions.Item label="Bathrooms">
                {this.state.selectedApartment.bathrooms}
              </Descriptions.Item>
              <Descriptions.Item label="Size" span={3}>
                {this.state.selectedApartment.size}
              </Descriptions.Item>
              <Descriptions.Item label="Region" span={1}>
                {businessInfo.region}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={2}>
                {businessInfo.address}
              </Descriptions.Item>
              <Descriptions.Item label="Amenities">
                <AmenityInformation
                  amenities={this.state.selectedApartment.amenities}
                  align='left'
                  size={24}
                ></AmenityInformation>
              </Descriptions.Item>
            </Descriptions>
            </div>
            
          </Drawer>
        ) : (
          <p></p>
        )}
      </Row>
    );
  }
}


