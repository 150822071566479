import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Home/index.js')),
  },
  {
    path: 'inbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    path: 'mailbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    path: 'calendar',
    component: asyncComponent(() => import('../Calendar/Calendar')),
  },
  {
    path: 'googlemap',
    component: asyncComponent(() => import('../Map/GoogleMap/googleMap')),
  },
  {
    path: 'leafletmap',
    component: asyncComponent(() => import('../Map/Leaflet/leaflet')),
  },
  {
    path: 'business',
    component: asyncComponent(() => import('../Business')),
  },
  {
    path: 'contacts',
    component: asyncComponent(() => import('../Contacts')),
  },
  {
    path: 'alert',
    component: asyncComponent(() => import('../Feedback/Alert')),
  },
  {
    path: 'modal',
    component: asyncComponent(() => import('../Feedback/Modal/')),
  },
  {
    path: 'message',
    component: asyncComponent(() => import('../Feedback/Message')),
  },
  {
    path: 'notification',
    component: asyncComponent(() => import('../Feedback/Notification')),
  },
  {
    path: 'Popconfirm',
    component: asyncComponent(() => import('../Feedback/Popconfirm')),
  },
  {
    path: 'spin',
    component: asyncComponent(() => import('../Feedback/Spin')),
  },
  {
    path: 'affix',
    component: asyncComponent(() => import('../Navigation/affix')),
  },
  {
    path: 'backToTop',
    component: asyncComponent(() => import('../Navigation/backToTop')),
  },
  {
    path: 'googleChart',
    component: asyncComponent(() => import('../Charts/googleChart')),
  },
  {
    path: 'reecharts',
    component: asyncComponent(() => import('../Charts/recharts')),
  },
  {
    path: 'menu',
    component: asyncComponent(() => import('../Navigation/menu')),
  },
  {
    path: 'ReactChart2',
    component: asyncComponent(() => import('../Charts/reactChart2')),
  },   
  {
    path: 'chat',
    component: asyncComponent(() => import('../Chat')),
  },
  ...customRoutes,
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
