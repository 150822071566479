import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Row, Col, Button, Input, Modal, Select } from "antd";
import appActions from "../../redux/app/actions";
import userAction from '../../redux/user/actions';
import TopbarNotification from "./topbarNotification";
import TopbarMessage from "./topbarMessage";
import TopbarSearch from "./topbarSearch";
import TopbarUser from "./topbarUser";
import Checkmark from "../../components/svg/Checkmark";
//import TopbarAddtoCart from "./topbarAddtoCart";
import TopbarWrapper from "./topbar.style";
import axios from "axios";
import defaultApi from '../../settings'
import {
  PhoneOutlined,
} from '@ant-design/icons';


const {Option} = Select;
const { Header } = Layout;
const { toggleCollapsed } = appActions;


class Topbar extends Component {
  state = {
    isOpen: false,
    loading: false,
    visible: false,
    contactForm : {},
    apartments : []
  };
  componentDidMount() {
    const {businessId} = this.props;
    this.getApartments();
    //this.props.checkUser();
  }

  handleContact(){
      let {contactForm} = this.state;
      const {businessId} = this.props;
      contactForm.businessId = businessId;
      console.log('this is contactForm', contactForm)
      axios.post(`${defaultApi.apiUrl}/leads/`, contactForm).then(response => {
        this.setState({
          formSubmitted : true
        })
        console.log('this is response', response);
      }).catch(function(error){
        console.log('this is error', error);
      })

  }

  formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}





  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  onFinish = values => {
    console.log('Received values of form:', values);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  submitContactForm = () => {
    this.handleContact();    
  }

  getApartments = () =>{
    const {businessId} = this.props;

    let apartments = [];

    const _apartments = axios.get(`${defaultApi.apiUrl}/apartments/`+businessId).then(results => {
      const a = results.data;
      a.map(apartment => {
        apartments.push(apartment.apt_number);        
      })
      
      
    }).catch(function (data){
      console.log(data);
    })

    this.setState({apartments});


  }

  handleSelectChange = (value, kind) => {
    console.log('this is value', value)
    const k = {};
    let child = '';
    let v = '';
    switch (kind) {
      case 'First Name':
        child = 'firstName';
        v = value.target.value;
        break;
      case 'Last Name':
        child = 'lastName';
        v = value.target.value;
        break;
      case 'Email':
        child = 'email';
        v = value.target.value;
        break;              
      case 'Phone Number':
        child = "phone";
         v = value.target.value;
        break;
      case 'Residence':
        child = "residence";
         v = value;
        break;
      case 'Reason For Contact':
        child = "reason";
        v = value;
        break;
    }


    this.setState({      
      contactForm : {
        ...this.state.contactForm,
        [child] : v
      }
    }, ()=> {
      console.log('this is contant ', this.state)
      console.log('tldlskdj', Object.keys(this.state.contactForm).length)
    })

    console.log('this is value', value)
    console.log('this is kind', kind)
  }

  render() {
    const { toggleCollapsed, user, url, customizedTheme, locale, businessInfo, designStyles } = this.props;
    console.log('this is businessInfo123', businessInfo)
    const {visible, contactForm, formSubmitted, apartments} = this.state;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
    console.log('this is apartments again', apartments)
    return (
      <TopbarWrapper>
         <Modal
          visible={visible}
          title="Submit the form below and we will be in touch."
          onCancel={this.handleCancel}

          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
        {!formSubmitted && 
        <div>
          <Input style={{marginBottom:20, height:40}} value={contactForm.firstName ? contactForm.firstName : ''} onChange={(value) => this.handleSelectChange(value, 'First Name')} type="text" placeholder="First Name" />
          <Input style={{marginBottom:20, height:40}} value={contactForm.lastName ? contactForm.lastName : ''} onChange={(value) => this.handleSelectChange(value, 'Last Name')} type="text" placeholder="Last Name" />
          <Input style={{marginBottom:20, height:40}} value={contactForm.email ? contactForm.email : ''} onChange={(value) => this.handleSelectChange(value, 'Email')} type="text" placeholder="Email Address" />
          <Input style={{marginBottom:20, height:40}} value={contactForm.phone ? contactForm.phone : ''} onChange={(value) => this.handleSelectChange(value, 'Phone Number')} type="text" placeholder="Phone Number" />
          <Select style={{ width: '48%', height:30}}  onChange={(value) => this.handleSelectChange(value, 'Residence')}   placeholder="Which Residence?">
            {apartments.map(a=> 
              <Option value={a}>{a}</Option>
            )}
          </Select>
          <Select style={{ width: '48%', marginLeft:'4%', height:30}}  onChange={(value) => this.handleSelectChange(value, 'Reason For Contact')}  placeholder="Reason For Contact?">
            <Option value="Residency Inquiry">Residency Inquiry</Option>
            <Option value="General Inquiry">General Inquiry</Option>
            <Option value="Existing Resident">Existing Resident</Option>
          </Select>
          <Button disabled={Object.keys(contactForm).length != 6} style={{color: Object.keys(contactForm).length != 6 ? '#ccc' : '#fff' , backgroundColor: Object.keys(contactForm).length != 6 ? '#fff' : '#14e3ba', width: '100%', fontWeight:'bold', marginTop:20, height:50}} onClick={this.submitContactForm}> Submit Request </Button>        
        </div>
        }
        {
          formSubmitted && 
          <div style={{textAlign: 'center'}}>
            <Checkmark width="100" height="100" color="#389e0d" />
            <div>Your submission has been received.</div>
          </div>
        }
        </Modal>
                {designStyles &&

        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
          {businessInfo.logo ? (
                <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                  <a href="/" style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img style={{maxHeight: '50px'}} src={businessInfo.logo} alt={businessInfo.name} />
                  <label style={{fontSize:'22px', fontWeight:'500', paddingTop: '5px', marginLeft:'20px', color:'#000'}}>{businessInfo.name}</label>
                  </a>
                </div>
          ) : (
            <label>
              {businessInfo.name}
            </label>
          )}

          </div>

          <ul className="isoRight">
            <li >
              {businessInfo.phone ? (
                <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                  <PhoneOutlined style={{...designStyles.primaryColor, fontSize:'14px', marginRight:'5px'}} /> <span style={{...designStyles.headerFont, textDecoration:'underline'}}>{this.formatPhoneNumber(businessInfo.phone)}</span>
                </label>
              ) : (
                <label>
                </label>
              )}
            </li>

            <li style={{marginRight:0}}>
              {businessInfo.email ? (
                <div>
                  <Button style={{...designStyles.primaryColor, ...designStyles.primaryBorder, background:'none'}} type="primary" size="large" onClick={this.showModal}>Message Us</Button>                  
                </div>                
              ) : (
                <label>
                  
                </label>
              )}
            </li>

            <li></li>
            <li></li>
          </ul>
          
        </Header>
        }
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme  
  }),
  { toggleCollapsed }
)(Topbar);




//            <img src={CHECKMARK} style={{ width:150}}/>
